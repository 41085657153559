@@ -1,76 +0,0 @@
<template>
  <v-card class="pa-8" width="100%">
    <h1 style="font-weight: 800; color: #e53935">ข้อมูลผู้ใช้งาน</h1>
    <v-row>
      <v-col cols="2"><p style="font-weight:800">username</p></v-col>
      <v-col cols="4">
        <span>{{ username }}</span>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="2"><p style="font-weight:800">password</p></v-col>
      <v-col cols="4">
        <span>{{ password }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2"><p style="font-weight:800">ชื่อ</p></v-col>
      <v-col cols="4">
        <span>{{ firstname }}</span>
      </v-col>
      <v-col cols="2"><p style="font-weight:800">นามสกุล</p></v-col>
      <v-col cols="4">
        <span>{{ lastname }}</span>
      </v-col>
      <v-col cols="2"><p style="font-weight:800">ชื่อเล่น</p></v-col>
      <v-col cols="4">
        <span>{{ nickName }}</span>
      </v-col>

      <v-col cols="2"><p style="font-weight:800">หมายเลขโทรศัพท์</p></v-col>
      <v-col cols="4">
        <span>{{ tel }}</span>
      </v-col>
      <v-col cols="2"><p style="font-weight:800">อีเมล</p></v-col>
      <v-col cols="4">
        <span>{{ email }}</span>
      </v-col>
      <v-col cols="2"><p style="font-weight:800">ที่อยู่</p></v-col>
      <v-col cols="4">
        <span>{{ address }}</span>
      </v-col>
      <v-col cols="2"><p style="font-weight:800">รหัสไปรษณีย์</p></v-col>
      <v-col cols="4">
        <span>{{ postalCode }}</span>
      </v-col>
      <v-col cols="2"><p style="font-weight:800">ประเภท</p></v-col>
      <v-col cols="4">
        <span>{{ type }}</span>
      </v-col>
      <v-col cols="2"><p style="font-weight:800" class="pt-5">activeFlag</p></v-col>
      <v-col cols="4">
        <v-switch v-model="activeFlag" label="ใช้งาน"></v-switch>
      </v-col>
      <!-- <v-col cols="2"><p style="font-weight:800">idCargImg</p></v-col>
      <v-col cols="4">
        idCargImg
      </v-col>
      <v-col cols="2"><p style="font-weight:800">idCargImg2</p></v-col>
      <v-col cols="4">
        idCargImg2
      </v-col>
      <v-col cols="2"><p style="font-weight:800">activeFlag</p></v-col>
      <v-col cols="4">
        activeFlag
      </v-col> -->
    </v-row>
    <v-row>
      <v-btn @click="cancel()">กลับ</v-btn>
      <v-spacer></v-spacer>
      <!-- <v-btn @click="submit()">บันทึก</v-btn> -->
    </v-row>
  </v-card>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      id: "",
      username: "",
      password: "",
      firstname: "",
      lastname: "",
      nickName: "",
      tel: "",
      email: "",
      address: "",
      postalCode: "",
      type: "",
      activeFlag: "",
      // count: 1
      // createdAt: "2021-03-12T16:57:35.000Z"
      // updatedAt: "2021-03-12T16:57:35.000Z"
    };
  },
  created() { this.year = JSON.parse(
        Decode.decode(localStorage.getItem("yearSelected"))
      );
    var userdata = JSON.parse(Decode.decode(localStorage.getItem("userdata")));
    console.log("userdata", userdata);
    this.id = userdata.id;
    this.username = userdata.username;
    this.password = userdata.password;
    this.firstname = userdata.firstname;
    this.lastname = userdata.lastname;
    this.nickName = userdata.nickName;
    this.tel = userdata.tel;
    this.email = userdata.email;
    this.address = userdata.address;
    this.postalCode = userdata.postalCode;
    this.type = userdata.type;
    this.activeFlag = userdata.activeFlag;
  },
  methods: {
    cancel() {
      this.$router.push("ManageUser");
    },
  },
};
</script>